// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Textfit } from 'react-textfit'
import { StaticQuery, graphql } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from './toc'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img: file(relativePath: { eq: "archive/cover.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CoverPage = React.memo(({ lang }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Fragment>
        <div>
          <div>
            <h1>Mother's Question & Answers</h1>
            <h2>The Auroville Radio Talks</h2>
          </div>
          <div>
            <GatsbyImage image={getImage(data.img)} />
          </div>
          <div>
            <div>
              <p>
                “Many people who are here forget one thing. They want to begin
                by the end. They think that they are ready to express in their
                life what they call the supramental Force or Consciousness, and
                they want to infuse this in their actions, their movements,
                their daily life. But the trouble is that they don’t at all know
                what the supramental Force or Consciousness is and that first of
                all it is necessary to take the reverse path, the way of
                interiorisation and of withdrawal from life, in order to find
                within oneself this Truth which has to be expressed.
              </p>
              <p>
                For as long as one has not found it, there is nothing to
                express. And by imagining that one is living an exceptional
                life, one lives only in the illusion of one’s exceptional
                state.”{' '}
              </p>
              <p>(Mother, Questions and Answers, November 2, 1955)</p>
            </div>
          </div>
        </div>
        <div>
          <div>&nbsp;</div>
          <div>
            <p className="dropcap">
              <strong style={{ fontWeight: 600 }}>
                <em>
                  <span>M</span>other's Question and Answers: The Auroville
                  Radio Talks
                </em>{' '}
                is an offering that emerges from talks recorded by Loretta
                Shartsis for Auroville Radio over the last seven years.
              </strong>
            </p>
            <p className="text-indent">
              Mirra Alfassa, known as the Mother of the Sri Aurobindo Ashram,
              was Sri Aurobindo’s partner and counterpart in every way. They had
              the same spiritual realisations and they worked for the same goal;
              to bring the New Consciousness for mankind. Mother was a true
              Guru, and a Universal Guru in every way. Her teaching has
              something for everyone because she taught for human progress on
              all levels and in all parts of the human being. The clarity and
              simplicity of her expression communicates something to everyone
              who reads her books. Her inner work included changing those who
              came to her; both those who were able to meet her physically and
              those who could come only in their inner being. Her consciousness
              was active on the universal subtle planes of creation, and she
              worked to enlighten thought and life impulse on a universal scale
              for all of mankind.
            </p>
            <p className="text-indent">
              Mother established the Sri Aurobindo Ashram after November 24,
              1926, when Sri Aurobindo retired to devote all his time to
              bringing the New Consciousness. She established the Ashram School
              in 1943, and taught the students French. From 1950 through 1958,
              in French, she taught them the highest, deepest, most complex
              spiritual and yogic philosophy and practice in a simple and easily
              understandable manner. She gave a realistic and practical view of
              Sri Aurobindo’s philosophy and the practice of his Yoga. These
              classes were transcribed and published as “Mother’s Questions and
              Answers”. The present series of Auroville Radio talks on Mother’s
              Questions and Answers begins on the date of July 12, 1955, because
              it is the first date of the available sound recordings of Mother
              teaching the class. The original recording of Mother’s class is
              part of the Radio Talk. It can be heard at the end of each talk.
              It is well worth listening to even of one does not understand
              French, because it imparts something of Mother’s consciousness to
              the listener.
            </p>
            <TableOfContents lang={lang} />
          </div>
          <div>&nbsp;</div>
        </div>
      </Fragment>
    )}
  />
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CoverPage
