// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import map from 'lodash/map'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import takeRight from 'lodash/takeRight'
import matches from 'lodash/matches'
import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import replace from 'lodash/replace'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    allResources(
      sort: { order: ASC, fields: position }
      filter: {
        contentBlocks: {
          elemMatch: { type: { eq: "image" }, subType: { eq: "cover" } }
        }
      }
    ) {
      edges {
        node {
          title {
            content
            lang
          }
          dateThisTalkWasGivenByTheMother
          position
          routeSlug
          contentBlocks {
            type
            subType
            caption
          }
        }
      }
    }
    lotus1: file(relativePath: { eq: "lotus/llotus1.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus2: file(relativePath: { eq: "lotus/llotus2.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus3: file(relativePath: { eq: "lotus/llotus3.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus4: file(relativePath: { eq: "lotus/llotus4.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus5: file(relativePath: { eq: "lotus/llotus5.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus6: file(relativePath: { eq: "lotus/llotus6.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus7: file(relativePath: { eq: "lotus/llotus7.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus8: file(relativePath: { eq: "lotus/llotus8.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus9: file(relativePath: { eq: "lotus/llotus9.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus10: file(relativePath: { eq: "lotus/llotus10.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus11: file(relativePath: { eq: "lotus/llotus11.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus12: file(relativePath: { eq: "lotus/llotus12.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus13: file(relativePath: { eq: "lotus/llotus13.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus14: file(relativePath: { eq: "lotus/llotus14.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus15: file(relativePath: { eq: "lotus/llotus15.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus16: file(relativePath: { eq: "lotus/llotus16.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus17: file(relativePath: { eq: "lotus/llotus17.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus18: file(relativePath: { eq: "lotus/llotus18.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus19: file(relativePath: { eq: "lotus/llotus19.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus20: file(relativePath: { eq: "lotus/llotus20.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus21: file(relativePath: { eq: "lotus/llotus21.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus22: file(relativePath: { eq: "lotus/llotus22.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus23: file(relativePath: { eq: "lotus/llotus23.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus24: file(relativePath: { eq: "lotus/llotus24.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus25: file(relativePath: { eq: "lotus/llotus25.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus26: file(relativePath: { eq: "lotus/llotus26.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus27: file(relativePath: { eq: "lotus/llotus27.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus28: file(relativePath: { eq: "lotus/llotus28.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus29: file(relativePath: { eq: "lotus/llotus29.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus30: file(relativePath: { eq: "lotus/llotus30.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus31: file(relativePath: { eq: "lotus/llotus31.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus32: file(relativePath: { eq: "lotus/llotus32.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus33: file(relativePath: { eq: "lotus/llotus33.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus34: file(relativePath: { eq: "lotus/llotus34.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus35: file(relativePath: { eq: "lotus/llotus35.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus36: file(relativePath: { eq: "lotus/llotus36.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus37: file(relativePath: { eq: "lotus/llotus37.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus38: file(relativePath: { eq: "lotus/llotus38.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus39: file(relativePath: { eq: "lotus/llotus39.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus40: file(relativePath: { eq: "lotus/llotus40.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus41: file(relativePath: { eq: "lotus/llotus41.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus42: file(relativePath: { eq: "lotus/llotus42.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus43: file(relativePath: { eq: "lotus/llotus43.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus44: file(relativePath: { eq: "lotus/llotus44.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus45: file(relativePath: { eq: "lotus/llotus45.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus46: file(relativePath: { eq: "lotus/llotus46.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus47: file(relativePath: { eq: "lotus/llotus47.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus48: file(relativePath: { eq: "lotus/llotus48.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus49: file(relativePath: { eq: "lotus/llotus49.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus50: file(relativePath: { eq: "lotus/llotus50.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus51: file(relativePath: { eq: "lotus/llotus51.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus52: file(relativePath: { eq: "lotus/llotus52.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus53: file(relativePath: { eq: "lotus/llotus53.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus54: file(relativePath: { eq: "lotus/llotus54.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus55: file(relativePath: { eq: "lotus/llotus55.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus56: file(relativePath: { eq: "lotus/llotus56.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus57: file(relativePath: { eq: "lotus/llotus57.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus58: file(relativePath: { eq: "lotus/llotus58.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus59: file(relativePath: { eq: "lotus/llotus59.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus60: file(relativePath: { eq: "lotus/llotus60.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus61: file(relativePath: { eq: "lotus/llotus61.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus62: file(relativePath: { eq: "lotus/llotus62.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus63: file(relativePath: { eq: "lotus/llotus63.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus64: file(relativePath: { eq: "lotus/llotus64.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus65: file(relativePath: { eq: "lotus/llotus65.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus66: file(relativePath: { eq: "lotus/llotus66.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus67: file(relativePath: { eq: "lotus/llotus67.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus68: file(relativePath: { eq: "lotus/llotus68.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus69: file(relativePath: { eq: "lotus/llotus69.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus70: file(relativePath: { eq: "lotus/llotus70.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus71: file(relativePath: { eq: "lotus/llotus71.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus72: file(relativePath: { eq: "lotus/llotus72.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus73: file(relativePath: { eq: "lotus/llotus73.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus74: file(relativePath: { eq: "lotus/llotus74.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus75: file(relativePath: { eq: "lotus/llotus75.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus76: file(relativePath: { eq: "lotus/llotus76.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus77: file(relativePath: { eq: "lotus/llotus77.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus78: file(relativePath: { eq: "lotus/llotus78.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus79: file(relativePath: { eq: "lotus/llotus79.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus80: file(relativePath: { eq: "lotus/llotus80.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus81: file(relativePath: { eq: "lotus/llotus81.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus82: file(relativePath: { eq: "lotus/llotus82.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus83: file(relativePath: { eq: "lotus/llotus83.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus84: file(relativePath: { eq: "lotus/llotus84.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus85: file(relativePath: { eq: "lotus/llotus85.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus86: file(relativePath: { eq: "lotus/llotus86.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus87: file(relativePath: { eq: "lotus/llotus87.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus88: file(relativePath: { eq: "lotus/llotus88.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus89: file(relativePath: { eq: "lotus/llotus89.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus90: file(relativePath: { eq: "lotus/llotus90.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus91: file(relativePath: { eq: "lotus/llotus91.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus92: file(relativePath: { eq: "lotus/llotus92.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus93: file(relativePath: { eq: "lotus/llotus93.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus94: file(relativePath: { eq: "lotus/llotus94.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus95: file(relativePath: { eq: "lotus/llotus95.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lotus96: file(relativePath: { eq: "lotus/llotus96.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const TOCRenderer = ({ nodes, nodePositions, update, lang, images }) => (
  <ul className="as-paragraph homepage-table-of-contents">
    {map(nodePositions, (nodePosition, index) => {
      const node = filter(
        nodes,
        matches({ position: head(nodePosition, 1) })
      )[0]

      const lotus = `lotus${index}`
      const caption = replace(
        replace(
          replace(node.contentBlocks[0].caption, '<strong>', ''),
          '</strong>',
          ''
        ),
        '<br />',
        ' '
      )

      return (
        <Fragment>
          <li>
            <Link to={node.routeSlug} onClick={() => update(false, 'root')}>
              <GatsbyImage image={getImage(images[lotus])} />
              <div>
                <span>
                  {`${node.intlTitle}, ${node.dateThisTalkWasGivenByTheMother}`}
                </span>
                <span>{caption}</span>
              </div>
            </Link>
          </li>
        </Fragment>
      )
    })}
  </ul>
)

/** TableOfContents */
const TableOfContents = React.memo(({ update = () => true, lang }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const nodes = map(data.allResources.edges, 'node').slice(0, -1)
      const intlNodes = map(nodes, (node) => ({
        ...node,
        intlTitle: filter(node.title, ['lang', lang])[0].content,
      }))
      const nodePositions = groupBy(map(intlNodes, 'position'), Math.floor)

      return (
        <TOCRenderer
          nodes={intlNodes}
          nodePositions={nodePositions}
          update={update}
          images={data}
        />
      )
    }}
  />
))

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default TableOfContents
